/* eslint-disable */
import * as Sentry from '@sentry/nextjs';

const ErrorReporting = {
  setUser(neighbor) {
    const { id, email } = neighbor;
    Sentry.setUser({ id, email });
  },

  unsetUser() {
    Sentry.configureScope((scope) => scope.setUser(null));
  },

  captureException(error) {
    Sentry.captureException(error);
  },
};

export default ErrorReporting;
