/* eslint-disable */
import apiClient, { API_TYPES } from '@services/hafh/api';
import { HTTP_UNPROCESSABLE_ENTITY_CODE } from '@utils/constants';
import {
  PayloadAction,
  PurchaseCoin,
  PurchaseCoinLimit,
  PurchaseCoinUnit,
} from '@utils/types';
import toast from '@utils/toast';

const api = apiClient(API_TYPES.API);

const initialState: PurchaseCoin = {
  purchaseCoinUnit: {
    id: 0,
    currency: {
      code: 0,
      symbol: '',
      decimal_point: 0,
    },
    coin_per_unit: 0,
    price_per_unit: 0,
  },
  // @ts-ignore
  purchaseCoinLimit: {},
};

// action types
export const GET_PURCHASE_COIN_UNIT =
  'hafh/purchase-coin/GET_PURCHASE_COIN_UNIT';
export const GET_PURCHASE_COIN_VALIDATE =
  'hafh/purchase-coin/GET_PURCHASE_COIN_VALIDATE';

// reducers
const purchaseCoin = (
  state: PurchaseCoin = initialState,
  action: PayloadAction<PurchaseCoinUnit | PurchaseCoinLimit>
) => {
  switch (action.type) {
    case GET_PURCHASE_COIN_UNIT:
      return {
        ...state,
        purchaseCoinUnit: {
          ...(action.payload as PurchaseCoinUnit),
        },
      };
    case GET_PURCHASE_COIN_VALIDATE:
      return {
        ...state,
        purchaseCoinLimit: {
          ...(action.payload as PurchaseCoinLimit),
        },
      };
    default:
      return state;
  }
};

// actions
export const getPurchaseCoinUnit =
  (authUser: any, locale: string) => async (dispatch: any) => {
    const res = await api.get(`neighbors/purchase_coin`, {}, locale, authUser);

    if (res) {
      dispatch({
        type: GET_PURCHASE_COIN_UNIT,
        payload: res,
      });
    }
  };

export const getPurchaseCoinValidate =
  (authUser: any, locale: string) => async (dispatch: any) => {
    const res = await api.get(
      `neighbors/purchase_coin/validate`,
      {},
      locale,
      authUser
    );

    if (res) {
      dispatch({
        type: GET_PURCHASE_COIN_VALIDATE,
        payload: res,
      });
    }
  };

export const postPurchaseCoin = async (
  unit: number,
  id: number,
  locale: string,
  authUser: any,
  redirect_url?: string
) => {
  const res = await api.post(
    `neighbors/purchase_coin`,
    { unit, id, redirect_url },
    locale,
    authUser
  );

  return res;
};

export const purchaseCoinPackages = async (
  locale: string,
  authUser: any,
  coinPacks: Array<{
    code: string;
    unit: number;
  }>
) => {
  const res = await api.post(
    `neighbors/purchase_coin_package`,
    { packages: coinPacks },
    locale,
    authUser
  );

  return res;
};

export const confirmThreeDSecure =
  (accessId: string, authUser: any, locale: string) => async () => {
    return await api.post(
      'neighbors/purchase_coin/confirm_three_d_secure',
      { access_id: accessId },
      locale,
      authUser,
      undefined,
      (error) => {
        if (error.response?.status === HTTP_UNPROCESSABLE_ENTITY_CODE) {
          toast.error('error.failedThreeDSecure', true);
        } else {
          toast.error('error.default', true);
        }
      }
    );
  };
export default purchaseCoin;
