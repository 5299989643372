import { User } from '@firebase/auth-types';
import apiClient, { API_TYPES } from '@services/hafh/api';
import { Property } from '@services/hafh/types/generated';
import { fitBounds, fitMapToArea } from '@utils/search-map';
import { LANG_LOCALE } from '@utils/types';
import { ParsedUrlQuery } from 'node:querystring';
import { Action, Dispatch } from 'redux';

const api = apiClient(API_TYPES.API);

type SearchMapState = {
  endDate: Date | null;
  latestQueryParams: ParsedUrlQuery | null;
  mapRef: { map: any; maps: any } | null;
  properties: Property[];
  property: Property | null;
  propertyCount: number;
  selectedProperties: Property[];
  filterSettings: { coins: { min: number; max: number }, properties_count: number };
  showRefreshButton: boolean;
  startDate: Date | null;
  updatePropertiesDone: boolean;
};

const initialState = {
  endDate: null,
  latestQueryParams: null,
  mapRef: null,
  properties: [],
  property: null,
  propertyCount: 0,
  selectedProperties: [],
  filterSettings: {},
  showRefreshButton: false,
  startDate: null,
  updatePropertiesDone: false,
};

// action types
export const UPDATE_PROPERTY = 'hafh/map/UPDATE_PROPERTY' as const;

export const UPDATE_PROPERTIES = 'hafh/map/UPDATE_PROPERTIES' as const;

export const UPDATE_SELECTED_PROPERTIES =
  'hafh/map/UPDATE_SELECTED_PROPERTIES' as const;

export const UPDATE_PROPERTY_COUNT = 'hafh/map/UPDATE_PROPERTY_COUNT' as const;

export const SET_UPDATE_PROPERTIES_DONE =
  'hafh/map/SET_UPDATE_PROPERTIES_DONE' as const;

export const SET_FILTER_SETTINGS =
  'hafh/map/SET_FILTER_SETTINGS' as const;

export const SET_SHOW_REFRESH_BUTTON =
  'hafh/map/SET_SHOW_REFRESH_BUTTON' as const;

export const SET_MAP_REF = 'hafh/map/SET_MAP_REF' as const;

export const SET_QUERY_PARAMS = 'hafh/map/SET_QUERY_PARAMS' as const;

// reducers
const SearchMap = (
  state: SearchMapState = initialState,
  action: ReturnType<
    | typeof updateProperty
    | typeof updateProperties
    | typeof updateSelectedProperties
    | typeof updatePropertyCount
    | typeof setUpdatePropertiesDone
    | typeof setFilterSettings
    | typeof setShowRefreshButton
    | typeof setMapRef
    | typeof setQueryParams
  >
) => {
  if (action.type === UPDATE_PROPERTY) {
    return {
      ...state,
      property: action.payload,
    };
  }

  if (action.type === UPDATE_PROPERTIES) {
    return {
      ...state,
      properties: action.payload,
    };
  }

  if (action.type === UPDATE_SELECTED_PROPERTIES) {
    return {
      ...state,
      selectedProperties: action.payload,
    };
  }

  if (action.type === UPDATE_PROPERTY_COUNT) {
    return {
      ...state,
      propertyCount: action.payload,
    };
  }

  if (action.type === SET_UPDATE_PROPERTIES_DONE) {
    return {
      ...state,
      updatePropertiesDone: action.payload,
    };
  }

  if (action.type === SET_FILTER_SETTINGS) {
    return {
      ...state,
      filterSettings: action.payload,
    };
  }

  if (action.type === SET_SHOW_REFRESH_BUTTON) {
    return {
      ...state,
      showRefreshButton: action.payload,
    };
  }

  if (action.type === SET_MAP_REF) {
    return {
      ...state,
      mapRef: action.payload,
    };
  }

  if (action.type === SET_QUERY_PARAMS) {
    return {
      ...state,
      latestQueryParams: action.payload,
    };
  }

  return state;
};

// actions creators
export const updateProperty = (property: Property | null) => ({
  payload: property,
  type: UPDATE_PROPERTY,
});

export const updateProperties = (properties: Property[] = []) => ({
  payload: properties,
  type: UPDATE_PROPERTIES,
});

export const updateSelectedProperties = (properties: Property[] = []) => ({
  payload: properties,
  type: UPDATE_SELECTED_PROPERTIES,
});

export const updatePropertyCount = (propertyCount: number) => ({
  payload: propertyCount,
  type: UPDATE_PROPERTY_COUNT,
});

export const setUpdatePropertiesDone = (updatePropertiesDone: boolean) => ({
  payload: updatePropertiesDone,
  type: SET_UPDATE_PROPERTIES_DONE,
});

export const setFilterSettings = (filterSettings: object) => ({
  payload: filterSettings,
  type: SET_FILTER_SETTINGS,
});

export const setShowRefreshButton = (showRefreshButton: boolean) => ({
  payload: showRefreshButton,
  type: SET_SHOW_REFRESH_BUTTON,
});

export const setMapRef = (mapRef: { map: any; maps: any }) => ({
  payload: mapRef,
  type: SET_MAP_REF,
});

export const setQueryParams = (params: ParsedUrlQuery) => ({
  payload: params,
  type: SET_QUERY_PARAMS,
});

// actions
export const getProperties =
  (locale: LANG_LOCALE, filters = {}, authUser?: User) =>
  async (dispatch: Dispatch<Action>) => {
    const properties = await api.getV2('properties', locale, {
      authUser,
      // Needs to pass hide the common error toast
      handleError: () => {},
      params: filters,
    });

    const propertiesWithLatLong =
      properties &&
      properties.filter(
        (property: Property) =>
          property.latitude && property.longitude && property.thumbnail_url
      );

    if (propertiesWithLatLong) {
      dispatch(updateProperties(propertiesWithLatLong));
      dispatch(updatePropertyCount(propertiesWithLatLong.length));
    }

    return propertiesWithLatLong;
  };

export const getPropertyFilters =
  (locale: LANG_LOCALE, filters = {}, authUser?: User) =>
  async (dispatch: Dispatch<Action>) => {
    const filterSettings = await api.getV2('properties/filters', locale, {
      authUser,
      // Needs to pass hide the common error toast
      handleError: () => {},
      params: filters,
    });

    dispatch(setFilterSettings(filterSettings));

    return filterSettings;
  };

export const showProperties =
  (
    properties: Property[] = [],
    mapRef: { map: any; maps: any } | null = null,
    boundingBox = null
  ) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(setUpdatePropertiesDone(true));

    if (mapRef && mapRef.map && mapRef.maps) {
      if (properties.length === 0) {
        if (boundingBox) {
          fitMapToArea(mapRef, null, boundingBox);
        }
      } else {
        fitBounds(mapRef.map, mapRef.maps, properties);
      }
    }

    if (properties.length === 0) {
      dispatch(setShowRefreshButton(false));
    }

    return properties;
  };

export default SearchMap;
