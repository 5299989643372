import { User } from '@firebase/auth-types';
import apiClient, { API_TYPES } from '@services/hafh/api';
import type { Neighbor, SubscribeResult } from '@services/hafh/types/generated';
import {
  HTTP_UNPROCESSABLE_ENTITY_CODE,
  SNOOZE_PLAN_KIND,
} from '@utils/constants';
import toast from '@utils/toast';
import { LANG_LOCALE, PayloadAction } from '@utils/types';
import { Action, Dispatch } from 'redux';

const api = apiClient(API_TYPES.API);

/*
  NOTE: initialState should be
  ``` const initialState: Neighbor = {
        id: 0,
        firebase_uid: '',
        nickname: '',
      ...
      }
  ```
  but if it is, isEmpty(neighbor) will not be able to judge login.
*/
// @ts-ignore
const initialState: Neighbor = {};

// action types
export const SET_NEIGHBOR = 'hafh/neighbor/SET_NEIGHBOR';

export const UPDATE_NEIGHBOR = 'hafh/neighbor/UPDATE_NEIGHBOR';

// reducers
const neighborReducers = (
  state: Neighbor = initialState,
  action: PayloadAction<Neighbor>
) => {
  switch (action.type) {
    case SET_NEIGHBOR: {
      return action.payload;
    }

    case UPDATE_NEIGHBOR: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

type GetNeighborProps = {
  authUser: User;
  callback?: () => void;
  locale: LANG_LOCALE;
};

// actions
export const getNeighbor =
  ({ authUser, callback, locale }: GetNeighborProps) =>
  async (dispatch: Dispatch<PayloadAction<Neighbor>>) => {
    const neighbor = await api.get(
      `neighbors/${authUser.uid}`,
      {},
      locale,
      authUser,
      null,
      // no-op on error otherwise toast appears
      () => {}
    );

    dispatch({
      payload: neighbor,
      type: SET_NEIGHBOR,
    });

    if (callback) {
      callback();
    }

    return neighbor;
  };

export const createNeighbor = async (
  authUser: User,
  language_code: string,
  referral_code?: string | null,
  utm?: object | null
) =>
  api.post(
    'neighbors',
    { language_code, referral_code, ...utm },
    language_code,
    authUser
  );

export const setNeighbor = (neighbor = {}) => ({
  payload: neighbor,
  type: SET_NEIGHBOR,
});

export const subscribeNeighbor =
  (authUser: User, data = {}, locale: string) =>
  async (dispatch: Dispatch<Action>) => {
    const res: SubscribeResult = await api.post(
      'neighbors/subscribe',
      data,
      locale,
      authUser,
      { 'hafh-iov': localStorage.getItem('hafh-iov') }
    );

    if (res?.neighbor) {
      dispatch({
        payload: res.neighbor,
        type: SET_NEIGHBOR,
      });
    }

    return res;
  };

export const updateNeighbor =
  (
    data: Record<string, never> | undefined,
    neighbor: Neighbor,
    authUser: User,
    locale: string,
    callback?: () => void,
    showToast = true
  ) =>
  async (dispatch: Dispatch<Action>) => {
    const res = await api.put(
      `neighbors/${neighbor.id}`,
      data,
      locale,
      authUser
    );

    if (res) {
      dispatch({
        payload: res,
        type: UPDATE_NEIGHBOR,
      });

      if (showToast) {
        toast.success('success.updatedProfile', true);
      }

      if (callback) {
        callback();
      }
    }

    return res;
  };

export const upgradeNeighborPlan =
  (
    data: Record<string, never> | undefined,
    authUser: User,
    locale: string,
    errorHandler = null
  ) =>
  async (dispatch: Dispatch<Action>) => {
    const res = await api.put(
      'neighbors/upgrade_neighbor_plan',
      data,
      locale,
      authUser,
      null,
      errorHandler
    );

    if (res) {
      toast.success('success.updatedNeighborPlan', true);

      dispatch({
        payload: res,
        type: SET_NEIGHBOR,
      });
    }

    return res;
  };

export const changeNeighborPlan =
  (
    data: Record<string, never> | undefined,
    authUser: User,
    locale: string,
    errorHandler = null
  ) =>
  async (dispatch: Dispatch<Action>) => {
    const res = await api.put(
      'neighbors/change_neighbor_plan',
      data,
      locale,
      authUser,
      null,
      errorHandler
    );

    if (res) {
      if (res.neighbor_plans[1].code === SNOOZE_PLAN_KIND) {
        toast.success('success.updatedNeighborPlanToSnooze', true);
      } else {
        toast.success('success.updatedNeighborPlan', true);
      }

      dispatch({
        payload: res,
        type: SET_NEIGHBOR,
      });
    }

    return res;
  };

export const updateNeighborImage =
  (data: Record<string, never> | undefined, authUser: User, locale: string) =>
  async (dispatch: Dispatch<Action>) => {
    const res = await api.put(
      'neighbors/profile_image',
      data,
      locale,
      authUser
    );

    if (res) {
      dispatch({
        payload: { profile_image_url: res.profile_image_url },
        type: UPDATE_NEIGHBOR,
      });
    }
  };

export const updateNeighborLanguage =
  (data: Record<string, never> | undefined, authUser: User, locale: string) =>
  async (dispatch: Dispatch<Action>) => {
    const res = await api.put('neighbor_language', data, locale, authUser);

    if (res) {
      dispatch({
        payload: { language: res.language },
        type: UPDATE_NEIGHBOR,
      });
    }
  };

export const updateNeighborCreditCard =
  (
    data: Record<string, never> | undefined,
    authUser: User,
    locale: string,
    callback?: () => void
  ) =>
  async (dispatch: Dispatch<Action>) => {
    const res = await api.put(
      'neighbors/update_credit_card',
      data,
      locale,
      authUser
    );

    if (res) {
      dispatch({
        payload: { payment_gateway: res.payment_gateway },
        type: UPDATE_NEIGHBOR,
      });

      if (res.payment) {
        toast.success('success.updatedNeighborCreditCardWithPayment', true);
      } else {
        toast.success('success.updatedNeighborCreditCard', true);
      }

      if (callback) {
        callback();
      }
    }
  };

export const validateNeighborPhoneNumber =
  (data: Record<string, never> | undefined, authUser: User, locale: string) =>
  async () =>
    api.post('neighbors/validate_phone_number', data, locale, authUser);

export const updateNeighborPhoneNumber =
  (data: Record<string, never> | undefined, authUser: User, locale: string) =>
  async (dispatch: Dispatch<Action>) => {
    const res = await api.put(
      'neighbors/update_phone_number',
      data,
      locale,
      authUser
    );

    if (res) {
      dispatch({
        payload: { phone_number: res.phone_number },
        type: UPDATE_NEIGHBOR,
      });

      toast.success('success.updatedPhoneNumber', true);
    }

    return res;
  };

export const updateReceiveEmail =
  (doReceive: boolean, authUser: User, locale: string) =>
  async (dispatch: Dispatch<Action>) => {
    const res = await api.put(
      '/neighbors/update_receive_email',
      {
        receive: doReceive,
      },
      locale,
      authUser
    );

    if (res) {
      toast.success('success.updatedEmailSubscription', true);
      dispatch({
        payload: res,
        type: UPDATE_NEIGHBOR,
      });
    }

    return res;
  };

export const unsubscribeNeighbor =
  (data: Record<string, never> | undefined, authUser: User, locale: string) =>
  async () =>
    api.put('neighbors/unsubscribe', data, locale, authUser);

export const cancelUnsubscribeNeighbor =
  (authUser: User, locale: string) => async () => {
    await api.put('neighbors/cancel_unsubscribe', undefined, locale, authUser);

    toast.success('success.cancelUnsubscribe', true);
  };

export const confirmThreeDSecure =
  (
    access_id: string,
    authUser: User,
    locale: string,
    payment_intent_id?: string,
    payment_intent_client_secret?: string,
    neighbor_plan_id?: string,
    promotion_plan_id?: string
  ) =>
  async () =>
    api.post(
      'neighbors/subscription_confirm_three_d_secure',
      {
        access_id,
        neighbor_plan_id,
        payment_intent_client_secret,
        payment_intent_id,
        promotion_plan_id,
      },
      locale,
      authUser,
      { 'hafh-iov': localStorage.getItem('hafh-iov') },
      (error: { response: { data: { [x: string]: any }; status: number } }) => {
        if (error.response?.status === HTTP_UNPROCESSABLE_ENTITY_CODE) {
          if (error.response?.data?.error) {
            toast.error(error.response.data.error, false);
          } else {
            toast.error('error.failedThreeDSecure', true);
          }
        } else {
          toast.error('error.default', true);
        }
      }
    );

export default neighborReducers;
