/* eslint-disable */
import apiClient, { API_TYPES } from '@services/hafh/api';
import { FlightProfile } from '@utils/types';

const api = apiClient(API_TYPES.API);

const initialState: FlightProfile | null = null;

// action types
export const GET_FLIGHT_PROFILE = 'hafh/flightProfile/GET_FLIGHT_PROFILE';

// reducers
const flightProfile = (
  state = initialState,
  action: { type: string; payload: FlightProfile[] }
) => {
  switch (action.type) {
    case GET_FLIGHT_PROFILE:
      return action.payload;
    default:
      return state;
  }
};
// actions
export const getFlightProfile =
  (locale: string, authUser: any) => async (dispatch) => {
    const res = await api.get(
      'neighbors/flight_profiles',
      {},
      locale,
      authUser
    );

    if (res) {
      dispatch({
        type: GET_FLIGHT_PROFILE,
        payload: res,
      });
    }
  };

export default flightProfile;
