/* eslint-disable */
import { User } from '@firebase/auth-types';
import apiClient, { API_TYPES } from '@services/hafh/api';
import { LANG_LOCALE } from '@utils/types';
import { isEmpty } from '@utils/utils';

const api = apiClient(API_TYPES.API);

const initialState = {
  properties: [],
  property: null,
  globalPromotionProperties: {
    taipei: [],
    others: [],
  },
};

// action types
export const UPDATE_LIST = 'hafh/property/UPDATE_LIST';
export const UPDATE_PROPERTY = 'hafh/property/UPDATE_PROPERTY';
export const UPDATE_GLOBAL_PROMOTION_PROPERTY_LIST =
  'hafh/property/UPDATE_GLOBAL_PROMOTION_PROPERTY_LIST';

// reducers
const Property = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case UPDATE_LIST:
      return {
        ...state,
        properties: payload.properties,
      };
    case UPDATE_PROPERTY:
      return {
        ...state,
        property: {
          ...payload.property,
          nearby: [payload.property, payload.property, payload.property],
        },
      };
    case UPDATE_GLOBAL_PROMOTION_PROPERTY_LIST:
      return {
        ...state,
        globalPromotionProperties: {
          ...state.globalPromotionProperties,
          [payload.selectedAreaKey]: payload.properties,
        },
      };
    default:
      return state;
  }
};

// actions creators
export const updateProperty = (property) => ({
  type: UPDATE_PROPERTY,
  payload: { property },
});

export const updatePropertyList = (properties = [], acceptLanguage) => ({
  type: UPDATE_LIST,
  payload: {
    properties,
    acceptLanguage,
  },
});

export const updateGlobalPromotionPropertyList = (
  properties = [],
  selectedAreaKey: string
) => ({
  type: UPDATE_GLOBAL_PROMOTION_PROPERTY_LIST,
  payload: {
    properties,
    selectedAreaKey,
  },
});

// actions
export const getPropertyList =
  (locale, filters = {}, authUser?) =>
  async (dispatch) => {
    const properties = await api.get('properties', filters, locale, authUser);

    if (properties) {
      dispatch(updatePropertyList(properties, locale));
    }
    return properties;
  };

export const getProperty =
  (id, locale, authUser = null, params = {}) =>
  async (dispatch) => {
    const path = `properties/${id}/property_room_types`;

    const property = await api.get(
      path,
      params,
      locale,
      authUser && !isEmpty(authUser) ? authUser : null
    );

    if (property) {
      dispatch(updateProperty(property));
    }
    return property;
  };

export const getGlobalPromotionPropertyList =
  (
    selectedAreaKey: string,
    locale: LANG_LOCALE,
    filters = {},
    authUser?: User
  ) =>
  async (dispatch) => {
    const properties = await api.get('properties', filters, locale, authUser);

    if (properties) {
      dispatch(updateGlobalPromotionPropertyList(properties, selectedAreaKey));
    }

    return properties;
  };

export default Property;
