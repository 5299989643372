import React from 'react';
import { ToastContentProps } from 'react-toastify';
import IconClose from '@svg-icons/IconClose';
import styles from './css';

const ToastClose = ({ closeToast }: Partial<ToastContentProps>) => (
  <button className="toast-close" onClick={closeToast} type="button">
    <IconClose />
    <style jsx={true}>{styles}</style>
  </button>
);

export default ToastClose;
