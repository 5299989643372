
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React, { FC, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Slide, ToastContainer } from 'react-toastify';
import { useAppSelector } from '@hooks/useAppSelector';
import * as authUserActions from '@redux/modules/authUser';
import * as campaignActions from '@redux/modules/campaign';
import * as loaderAuthUserActions from '@redux/modules/loaderAuthUser';
import * as loaderCampaignActions from '@redux/modules/loaderCampaign';
import * as loaderLocationActions from '@redux/modules/loaderLocation';
import * as loaderNeighborActions from '@redux/modules/loaderNeighbor';
import * as locationActions from '@redux/modules/location';
import * as neighborActions from '@redux/modules/neighbor';
import * as neighborPlanActions from '@redux/modules/neighborPlans';
import * as unreadCountActions from '@redux/modules/unreadCount';
import reduxWrapper from '@redux/store';
import Firebase from '@services/firebase';
import { getLocationByIp } from '@services/hafh/location';
import { Location } from '@services/hafh/types/generated';
import { getStripe } from '@services/stripe';
import Analytics from '@utils/analytics';
import base from '@utils/base.css';
import { createCookie, eraseCookie, readCookie } from '@utils/cookie';
import ErrorReporting from '@utils/error-reporting';
import { useActions, useDayJsLocale, useMomentLocale } from '@utils/hooks';
import nprogress from '@utils/nprogress.css';
import routes from '@utils/routes';
import { sendVirtualPageView } from '@utils/spa-ga4';
import toastify from '@utils/toastify.css';
import { isEmpty } from '@utils/utils';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isbot } from 'isbot';
import { NextPage } from 'next';
import App, { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { IncomingMessage } from 'node:http';
import NProgress from 'nprogress';
import '@utils/wdyr';
import 'react-dates/lib/css/_datepicker.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import '@utils/sanitize.css';
import 'react-toastify/dist/ReactToastify.min.css';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(isBetween);

const PUBLIC_FILE = /\.(.*)$/;

const setHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const CommonApp: FC<AppProps> = ({ Component, ...rest }) => {
  const { props } = reduxWrapper.useWrappedStore(rest);
  const { pageProps } = props;
  const router = useRouter();
  const { asPath, events, locale, pathname } = router;

  const { authUser, loadingAuthUser, loadingNeighbor, location, neighbor } =
    useAppSelector((state) => ({
      authUser: state.authUser,
      loadingAuthUser: state.loadingAuthUser,
      loadingNeighbor: state.loadingNeighbor,
      location: state.location,
      neighbor: state.neighbor,
      neighborPlans: state.neighborPlans,
    }));

  const {
    getCampaigns,
    getLocation,
    getNeighbor,
    getNeighborPlans,
    getUnreadCount,
    setAuthUser,
    setNeighbor,
    stopLoaderAuthUser,
    stopLoaderCampaign,
    stopLoaderLocation,
    stopLoaderNeighbor,
  } = useActions({
    ...campaignActions,
    ...loaderCampaignActions,
    ...authUserActions,
    ...neighborActions,
    ...locationActions,
    ...unreadCountActions,
    ...loaderAuthUserActions,
    ...loaderLocationActions,
    ...loaderNeighborActions,
    ...neighborPlanActions,
  });

  useEffect(() => {
    const { href } = window.location;
    const { title } = window.document;

    // property page sent page view log in the page component separately.
    if (!loadingNeighbor && pathname !== routes.property) {
      sendVirtualPageView({ neighbor, title, url: href });
    }
  }, [asPath, loadingNeighbor]);

  useEffect(() => {
    events.on('routeChangeStart', NProgress.start);
    events.on('routeChangeComplete', NProgress.done);
    events.on('routeChangeError', NProgress.done);
  }, []);

  useEffect(() => {
    getStripe();
  }, []);

  useEffect(() => {
    Analytics.persistParams();
  }, []);

  useEffect(() => {
    (async () => {
      await getLocation(locale);
      stopLoaderLocation();
    })();
  }, []);

  useEffect(() => {
    events.on('routeChangeComplete', () => {
      window.scrollTo(0, 0);
    });
  }, []);

  const setNextLocaleCookie = () => {
    const localeFromCookie = readCookie('NEXT_LOCALE');

    if (localeFromCookie && localeFromCookie !== locale) {
      router.push(asPath, undefined, { locale: localeFromCookie });
    } else if (localeFromCookie || isEmpty(location) || !location.language) {
      //Do nothing
    } else if (navigator.cookieEnabled) {
      createCookie('NEXT_LOCALE', location.language.abbreviation);
      router.push(asPath, undefined, {
        locale: location.language.abbreviation,
      });
    }
  };

  useEffect(() => {
    // do not redirect locale for bots
    if (isbot(navigator.userAgent)) {
      setAuthUser({});
      setNeighbor({});
      stopLoaderAuthUser();
      stopLoaderNeighbor();

      return;
    }

    const firebase = new Firebase(locale);
    const unsubscribe = firebase.auth.onAuthStateChanged(async (fbAuthUser) => {
      if (fbAuthUser) {
        await setAuthUser(fbAuthUser);
        const fetchedNeighbor = await getNeighbor({
          authUser: fbAuthUser,
          locale,
        });

        const { href } = window.location;

        if (
          isEmpty(fetchedNeighbor) &&
          !href.includes('signin') &&
          !href.includes('signup')
        ) {
          // for legal reasons, firebase users without a neighbor account
          // must agree to the terms and agree to receive emails
          await router.replace(routes.signinConsent);

          return;
        }

        if (isEmpty(fetchedNeighbor)) {
          setNextLocaleCookie();
        } else {
          ErrorReporting.setUser(fetchedNeighbor);

          const neighborLocale = fetchedNeighbor.language.abbreviation;
          createCookie('NEXT_LOCALE', neighborLocale);
          createCookie('hafhLoggedIn', true);

          if (locale !== neighborLocale) {
            router.push(asPath, undefined, {
              locale: neighborLocale,
            });
          }

          window.gtag('set', 'user_properties', {
            neighbor_id: fetchedNeighbor.id,
            neighbor_plan_id: fetchedNeighbor.neighbor_plans[0].id,
          });
        }
      } else {
        await setAuthUser({});
        await setNeighbor({});
        ErrorReporting.unsetUser();
        eraseCookie('hafhLoggedIn');
        setNextLocaleCookie();
      }
      stopLoaderAuthUser();
      stopLoaderNeighbor();
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [locale, location]);

  useEffect(() => {
    const handle = setInterval(
      async () => {
        const firebase = new Firebase(locale);
        const user = firebase.auth.currentUser;

        if (user) {
          await user.getIdToken(true);
        }
      },
      10 * 60 * 1000
    );

    return () => clearInterval(handle);
  }, []);

  useEffect(() => {
    if (!loadingAuthUser && !loadingNeighbor && !isEmpty(neighbor)) {
      getUnreadCount(authUser, locale);

      if (Analytics.campaignApplied()) {
        Analytics.removeParams();
      }
    }
  }, [loadingAuthUser, loadingNeighbor, pathname]);

  useEffect(() => {
    (async () => {
      if (!loadingAuthUser) {
        await getCampaigns(authUser, locale);
        stopLoaderCampaign();
      }
    })();
  }, [loadingAuthUser]);

  // TODO: delete this after 202404 campaign
  useEffect(() => {
    if (!loadingAuthUser) {
      getNeighborPlans(locale, isEmpty(authUser) ? null : authUser);
    }
  }, [authUser, loadingAuthUser]);

  useMomentLocale(locale);

  useDayJsLocale(locale);

  /*
    Hotfix for Unable to search properties with dates in Android
    https://www.notion.so/kabukstyle/2022-04-06-Unable-to-search-properties-with-dates-in-Android-f464159332bf475184d95ef66cbbb7cc
  */
  useEffect(() => {
    setHeight();

    window.addEventListener('resize', setHeight);
  }, []);

  return <Component {...pageProps} />;
};

const MyApp: NextPage<AppProps> = ({ Component, ...rest }) => {
  const { store } = reduxWrapper.useWrappedStore(rest);

  return (
    <Provider store={store}>
      <Head>
        <link href="/manifest.json" rel="manifest" />
        <link href="/favicon.ico" rel="icon" />
        <link
          href="/favicon-16x16.png"
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
        <link
          href="/favicon-32x32.png"
          rel="icon"
          sizes="32x32"
          type="image/png"
        />
        <link href="/apple-touch-icon.png" rel="apple-touch-icon" />
        <meta content="#ffffff" name="theme-color" />
        <meta content="initial-scale=1.0, width=device-width" name="viewport" />
        {process.env.NEXT_PUBLIC_CLUSTER_ENV !== 'production' && (
          <meta content="noindex,nofollow" name="robots" />
        )}
      </Head>
      {/* Sentry */}
      <Script
        crossOrigin="anonymous"
        onLoad={() => {
          // Raven types not included since it's loaded remotely
          // @ts-ignore
          Raven.config(`${process.env.NEXT_PUBLIC_SENTRY_DSN}`).install();

          window.addEventListener(
            'error',
            (error) => {
              // eslint-disable-next-line no-console
              console.error(
                `Window error event: ${(error.target as HTMLInputElement).src}`
              );
              // Raven types not included since it's loaded remotely
              // @ts-ignore
              Raven.captureMessage('Window error event', {
                extra: {
                  src: (error.target as HTMLInputElement).src,
                },
                level: 'error',
              });
            },
            true
          );
        }}
        src="https://cdn.ravenjs.com/3.26.4/raven.min.js"
      />
      {/* Google Analytics 4 */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
      />
      <Script id="gtm-inline">
        {`window.dataLayer = window.dataLayer || []
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date())
          gtag('config', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}', {
            send_page_view: false
          })`}
      </Script>
      <CommonApp Component={Component} {...rest} />
      <ToastContainer
        className="hafh-toast"
        hideProgressBar={true}
        newestOnTop={true}
        theme="dark"
        transition={Slide}
      />
      <style global={true} jsx={true}>
        {base}
      </style>
      <style global={true} jsx={true}>
        {nprogress}
      </style>
      <style global={true} jsx={true}>
        {toastify}
      </style>
    </Provider>
  );
};

const getLocationByRequest = async (
  req: IncomingMessage,
  locale?: string
): Promise<Location> => {
  if (req.headers['x-hafh-client-ip']) {
    return getLocationByIp(req.headers['x-hafh-client-ip'], locale);
  }

  const forwarded = req.headers['x-forwarded-for'];

  try {
    const ip = forwarded
      ? (forwarded as string).split(/, /)[0]
      : req.socket.remoteAddress;

    return await getLocationByIp(ip ?? null, locale);
  } catch {
    return await getLocationByIp(null, locale);
  }
};

MyApp.getInitialProps = reduxWrapper.getInitialAppProps(
  (store) => async (context) => {
    if (
      PUBLIC_FILE.test(context.ctx.pathname) ||
      context.ctx.pathname.startsWith('/_next')
    ) {
      return App.getInitialProps(context);
    }

    if (context.ctx.req) {
      // Set initial locale to show correct lang for user
      // @ts-ignore
      const location = await getLocationByRequest(
        context.ctx.req,
        context.ctx.locale
      );
      store.dispatch(locationActions.updateLocation(location));
    }

    return App.getInitialProps(context);
  }
);

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  