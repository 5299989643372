/* eslint-disable */
import apiClient, { API_TYPES } from '@services/hafh/api';
import toast from '@utils/toast';

const api = apiClient(API_TYPES.API);

const initialState = {};

// reducers
const contact = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

// actions
export const sendContact = (payload, locale) => async () => {
  await api.post('contact', payload, locale);
  // res is null even when this request is successed.
  toast.success('success.sendSuccess', true);
};

export default contact;
