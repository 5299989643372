/* eslint-disable */
import apiClient, { API_TYPES } from '@services/hafh/api';
import { GetFlightsParams } from '@utils/types/request';

import { Flight } from '@services/hafh/types/generated';

const api = apiClient(API_TYPES.API);

const initialState: Flight[] = [];

// action types
export const GET_FLIGHTS = 'hafh/flights/GET_FLIGHTS';

// reducers
const flights = (
  state = initialState,
  action: { type: string; payload: Flight[] }
) => {
  switch (action.type) {
    case GET_FLIGHTS:
      return action.payload;
    default:
      return state;
  }
};
// actions
export const getFlights =
  (params: GetFlightsParams, locale: string) => async (dispatch) => {
    const res = await api.get('flights', params, locale);

    if (res) {
      dispatch({
        type: GET_FLIGHTS,
        payload: res,
      });
    }
  };

export default flights;
