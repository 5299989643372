import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  .icon {
    width: 24px;
    height: 24px;
    &.color-black400 {
      path {
        fill: ${COLORS.black400};
      }
    }

    svg,
    path {
      fill: currentColor;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export default styles;
