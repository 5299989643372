import { Dispatch } from 'react';
import { User } from '@firebase/auth-types';
import apiClient, { API_TYPES } from '@services/hafh/api';
import {
  Reservation,
  TravelStoryWritableReservations,
} from '@services/hafh/types/generated';
import toast from '@utils/toast';
import { LANG_LOCALE } from '@utils/types';
import { V2FlightReservationHotelOutput } from '@utils/types/v2-flight-reservation-new';
import { Action } from 'redux';

const api = apiClient(API_TYPES.API);

type ReservationsState = {
  reservations: Reservation[];
  total_count: number;
};

type ReservationState = {
  past: ReservationsState;
  reservable: boolean;
  reservation: null | Reservation;
  storyWritable: null | TravelStoryWritableReservations | [];
  upcoming: ReservationsState;
};

const defaultReservationsObject: ReservationsState = {
  reservations: [],
  total_count: 0,
};

const initialState: ReservationState = {
  past: defaultReservationsObject,
  reservable: false,
  reservation: null,
  storyWritable: null,
  upcoming: defaultReservationsObject,
};

export const UPDATE_UPCOMING_RESERVATIONS =
  'hafh/reservations/UPDATE_UPCOMING_RESERVATIONS' as const;

export const UPDATE_PAST_RESERVATIONS =
  'hafh/reservations/UPDATE_PAST_RESERVATIONS' as const;

export const UPDATE_RESERVATION =
  'hafh/reservations/UPDATE_RESERVATION' as const;

export const UPDATE_RESERVABLE = 'hafh/reservations/UPDATE_RESERVABLE' as const;

export const UPDATE_STORY_WRITABLE =
  'hafh/reservations/UPDATE_STORY_WRITABLE' as const;

const Reservations = (
  state: ReservationState = initialState,
  action:
    | ReturnType<typeof updateReservation>
    | ReturnType<typeof updateReservationReservable>
    | ReturnType<typeof updateUpcomingReservations>
    | ReturnType<typeof updatePastReservations>
    | ReturnType<typeof updateReservationStoryWritable>
) => {
  if (action.type === UPDATE_PAST_RESERVATIONS) {
    return {
      ...state,
      past: action.reservations,
    };
  }

  if (action.type === UPDATE_RESERVABLE) {
    return {
      ...state,
      reservable: action.payload,
    };
  }

  if (action.type === UPDATE_RESERVATION) {
    return {
      ...state,
      reservation: action.payload,
    };
  }

  if (action.type === UPDATE_UPCOMING_RESERVATIONS) {
    return {
      ...state,
      upcoming: action.reservations,
    };
  }

  if (action.type === UPDATE_STORY_WRITABLE) {
    return {
      ...state,
      storyWritable: action.payload.storyWritable,
    };
  }

  return state;
};

export const updateUpcomingReservations = (
  reservations = defaultReservationsObject
) => ({
  // TODO: check if this is correct
  reservations,
  type: UPDATE_UPCOMING_RESERVATIONS,
});

export const updatePastReservations = (
  reservations = defaultReservationsObject
) => ({
  // TODO: check if this is correct
  reservations,
  type: UPDATE_PAST_RESERVATIONS,
});

export const updateReservation = (reservation: Reservation) => ({
  payload: reservation,
  type: UPDATE_RESERVATION,
});

export const updateReservationReservable = (reservable = false) => ({
  payload: reservable,
  type: UPDATE_RESERVABLE,
});

export const updateReservationStoryWritable = (
  storyWritable: TravelStoryWritableReservations
) => ({
  payload: { storyWritable },
  type: UPDATE_STORY_WRITABLE,
});

export const createReservation =
  (
    postData: any,
    authUser: User,
    locale: LANG_LOCALE,
    successCallback: (reservation: Reservation) => void,
    errorCallback: (error: Error) => Promise<boolean>
  ) =>
  async () => {
    const reservation = await api.post(
      'neighbors/reservations',
      postData,
      locale,
      authUser,
      {},
      errorCallback
    );

    if (reservation) {
      if (successCallback) {
        await successCallback(reservation);
      }
      toast.success('success.booking', true);
    }
  };

export const getReservation =
  (confirmationNumber: string, authUser: User, locale: LANG_LOCALE) =>
  async (dispatch: Dispatch<Action>) => {
    const reservation = await api.get(
      `neighbors/reservations/${confirmationNumber}`,
      {},
      locale,
      authUser
    );

    if (reservation) {
      dispatch(updateReservation(reservation));
    }
  };

export const cancelReservation =
  (reservationId: number, authUser: User, locale: LANG_LOCALE) => async () => {
    const reservation = await api.post(
      `neighbors/reservations/${reservationId}/cancel`,
      {},
      locale,
      authUser
    );

    if (reservation) {
      toast.success('success.canceledBooking', true);
    }
  };

export const acceptReservation =
  (reservationId: number, authUser: User, locale: LANG_LOCALE) => async () => {
    const reservation = await api.put(
      `neighbors/reservations/${reservationId}/accept`,
      {},
      locale,
      authUser
    );

    if (reservation) {
      toast.success('success.acceptedBooking', true);
    }
  };

export const declineReservation =
  (reservationId: number, authUser: User, locale: LANG_LOCALE) => async () => {
    const reservation = await api.put(
      `neighbors/reservations/${reservationId}/decline`,
      {},
      locale,
      authUser
    );

    if (reservation) {
      toast.success('success.declinedBooking', true);
    }
  };

export const getUpcomingReservations =
  (authUser: User, locale: LANG_LOCALE, pageIndex = 1) =>
  async (dispatch: Dispatch<Action>) => {
    const reservations = await api.get(
      'neighbors/reservations/upcoming',
      { page: pageIndex },
      locale,
      authUser
    );

    if (reservations) {
      dispatch(updateUpcomingReservations(reservations));
    }
  };

export const getPastReservations =
  (authUser: User, locale: LANG_LOCALE, pageIndex = 1) =>
  async (dispatch: Dispatch<Action>) => {
    const reservations = await api.get(
      'neighbors/reservations/past',
      { page: pageIndex },
      locale,
      authUser
    );

    if (reservations) {
      dispatch(updatePastReservations(reservations));
    }
  };

export const getReservationConfirmation =
  (data: any, authUser: User, locale: LANG_LOCALE) =>
  async (dispatch: Dispatch<Action>) => {
    const res = await api.get(
      'neighbors/reservations/confirmation',
      data,
      locale,
      authUser
    );

    if (res) {
      dispatch(updateReservationReservable(res.reservable));
    }
  };

export const validateReservation = async (
  postData: any,
  locale: LANG_LOCALE,
  authUser: User,
  successCallback: null | (() => void) = null,
  errorCallback: () => void
) => {
  const res = await api.post(
    'neighbors/reservations/validate',
    postData,
    locale,
    authUser,
    {},
    errorCallback
  );

  // when validation fails, res will be false
  if (res !== false && successCallback) {
    await successCallback();
  }
};

export const getReservationsForFlight = async (
  authUser: User,
  locale: LANG_LOCALE,
  pageIndex = 1
): Promise<V2FlightReservationHotelOutput> =>
  api.get(
    '/v2_flight_reservations/reservations',
    { page: pageIndex },
    locale,
    authUser
  );

export const getReservationsStoryWritable =
  (authUser: User, locale: LANG_LOCALE) =>
  async (dispatch: Dispatch<Action>) => {
    const reservations = await api.get(
      'neighbors/reservations/story_writable',
      {},
      locale,
      authUser
    );

    if (reservations) {
      dispatch(updateReservationStoryWritable(reservations));
    }
  };

export default Reservations;
