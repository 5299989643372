/* eslint-disable */
import apiClient, { API_TYPES } from '@services/hafh/api';
const api = apiClient(API_TYPES.API);

const initialState = [];

export const UPDATE_FEATURED_PROPERTIES =
  'hafh/featuredProperties/UPDATE_FEATURED_PROPERTIES';

const FeaturedProperties = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FEATURED_PROPERTIES:
      return [...action.payload];
    default:
      return state;
  }
};

export const updateFeaturedproperties = (featuredProperties = []) => ({
  type: UPDATE_FEATURED_PROPERTIES,
  payload: featuredProperties,
});

export const getFeaturedProperties =
  (locale: string, clientIpAddress: string | string[] | null) =>
  async (dispatch) => {
    const properties = await api.get(
      'properties/popular',
      { clientIpAddress },
      locale
    );

    if (properties) {
      dispatch(updateFeaturedproperties(properties));
      return properties;
    }
  };

export const getRecommendedProperties =
  (locale: string, clientIpAddress: string | string[] | null) =>
  async (dispatch) => {
    const properties = await api.get(
      'properties/recommended',
      { clientIpAddress },
      locale
    );

    if (properties) {
      dispatch(updateFeaturedproperties(properties));
      return properties;
    }
  };

export default FeaturedProperties;
