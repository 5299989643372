/* eslint-disable */
import { global } from 'styled-jsx/css';

import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const { mobile } = BREAKPOINT;

export default global`
  * {
    box-sizing: border-box;
  }

  html {
    -webkit-text-size-adjust: 100%;
  }

  body {
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    text-rendering: auto;
    font-size: 13px;
    color: ${COLORS.lightBlack};
    background-color: ${COLORS.white};
    font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    color: ${COLORS.black};
  }

  a {
    color: ${COLORS.primary};
  }

  ul {
    padding: 0;
    list-style: none;
  }

  *:focus {
    outline: none;
  }

  .clear {
    zoom: 1;
  }

  .clear:before,
  .clear:after {
    content: "";
    display: block;
  }

  .clear:after {
    clear: both;
  }

  .container {
    padding: 0 30px;
    max-width: 1280px;
    margin: 0 auto;

    @media screen and (max-width: ${mobile}px) {
      padding: 0 20px;
    }
  }

  .container-inner {
    max-width: 920px;
  }

  .container-wide {
    @media screen and (max-width: ${mobile}px) {
      padding: 0 25px;
    }
  }

  .container-inner {
    max-width: 920px;
  }

  .container-fluid {
    max-width: 100%;
  }

  .container-inner {
    max-width: 920px;
  }

  .icon-message-arrow {
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    overflow: hidden;
    top: 50%;
    bottom: 0;
    right: 23px;
    border: solid ${COLORS.iconArrowLightGray};
    border-width: 0 1px 1px 0;
    transform: rotate(-45deg) translateY(-50%);

    &.left {
      transform: rotate(135deg) translateY(-50%);
    }

    &.up {
      transform: rotate(-135deg) translateY(-50%);
    }

    &.down {
      transform: rotate(45deg) translateY(-50%);
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .relative {
    position: relative;
  }
  .rounded-none {
    border-radius: 0px;
  }
  .rounded-sm {
    border-radius: 2px;
  }
  .rounded-md {
    border-radius: 4px;
  }
  .rounded-lg {
    border-radius: 8px;
  }
  .rounded-full {
    border-radius: 50%;
  }
  .block {
    display: block;
  }
  .z-10 {
    z-index: 10;
  }
  .z-20 {
    z-index: 20;
  }
  .z-30 {
    z-index: 30;
  }
  .z-40 {
    z-index: 40;
  }
  .z-50 {
    z-index: 50;
  }
  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-white {
    color: #fff;
  }
  .font-normal {
    font-weight: 400;
  }
  .font-medium {
    font-weight: 500;
  }
  .font-bold {
    font-weight: 700;
  }
  .rotate-90 {
    transform: rotate(90deg);
  }
  .rotate-180 {
    transform: rotate(180deg);
  }
  .flex {
    display: flex;
  }
  .inline-flex {
    display: inline-flex;
  }
  .hidden {
    display: none;
  }
  .items-center {
    align-items: center;
  }
  .justify-center {
    justify-content: center;
  }
  .absolute {
    position: absolute;
  }
  .inset-0 {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .inset-x-0 {
    left: 0;
    right: 0;
  }
  .inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .top-0 {
    top: 0;
  }
  .bottom-0 {
    bottom: 0;
  }
  .right-0 {
    right: 0;
  }
  .left-0 {
    left: 0;
  }
  .w-full {
    width: 100%;
  }
  .h-full {
    height: 100%;
  }
  .aspect-wide {
    padding-top: 66%;
  }
  .mobile {
    @media screen and (min-width: ${BREAKPOINT.desktop}px) {
      display: none;
    }
  }
  .pc {
    @media screen and (max-width: ${BREAKPOINT.mobile}px) {
      display: none;
    }
  }
`;
