/* eslint-disable */
import apiClient, { API_TYPES } from '@services/hafh/api';

const api = apiClient(API_TYPES.API);

const initialState = {};

export const UPDATE_PROPERTY_PLAN_VACANCY =
  'hafh/propertyPlanVacancy/UPDATE_PROPERTY_PLAN_VACANCY';

const PropertyPlanVacancy = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROPERTY_PLAN_VACANCY:
      return action.payload;
    default:
      return state;
  }
};

export const updatePropertyPlanVacancy = (propertyPlanVacancy = {}) => ({
  type: UPDATE_PROPERTY_PLAN_VACANCY,
  payload: propertyPlanVacancy,
});

export const getPropertyPlanVacancy =
  (propertyPlanId: number) => async (dispatch) => {
    const propertyPlanVacancy = await api.get(
      `property_plans/${propertyPlanId}/vacancy`
    );

    if (propertyPlanVacancy) {
      dispatch(updatePropertyPlanVacancy(propertyPlanVacancy));
      return propertyPlanVacancy;
    }
  };

export default PropertyPlanVacancy;
