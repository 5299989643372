/* eslint-disable */
import apiClient, { API_TYPES } from '@services/hafh/api';
import { FlightVoucher } from '@utils/types';

const api = apiClient(API_TYPES.API);

const initialState: FlightVoucher[] = [];

// action types
export const GET_FLIGHT_VOUCHERS = 'hafh/flightVouchers/GET_FLIGHT_VOUCHERS';

// reducers
const flightVouchers = (
  state = initialState,
  action: { type: string; payload: FlightVoucher[] }
) => {
  switch (action.type) {
    case GET_FLIGHT_VOUCHERS:
      return action.payload;
    default:
      return state;
  }
};
// actions
export const getFlightVouchers =
  (locale: string, authUser: any) => async (dispatch) => {
    const res = await api.get(
      'neighbors/flight_vouchers',
      {},
      locale,
      authUser
    );

    if (res) {
      dispatch({
        type: GET_FLIGHT_VOUCHERS,
        payload: res,
      });
    }
  };

export default flightVouchers;
