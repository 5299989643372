import { Dispatch } from 'react';
import apiClient, { API_TYPES } from '@services/hafh/api';
import { NeighborCampaigns } from '@services/hafh/types/generated';
import { Campaign, PayloadAction } from '@utils/types';

type CampaignState = {
  campaign: Campaign | null;
  campaigns: NeighborCampaigns | null;
};

const api = apiClient(API_TYPES.API);

const initialState: CampaignState = { campaign: null, campaigns: null };

// action types
export const SET_CAMPAIGN = 'hafh/campaign/SET_CAMPAIGN';

export const SET_CAMPAIGNS = 'hafh/campaign/SET_CAMPAIGNS';

type CampaignAction =
  | {
      payload: Campaign;
      type: typeof SET_CAMPAIGN;
    }
  | { payload: NeighborCampaigns; type: typeof SET_CAMPAIGNS };

// reducers
const campaignReducers = (
  state: CampaignState = initialState,
  action: CampaignAction
) => {
  if (action.type === SET_CAMPAIGN) {
    return { ...state, campaign: action.payload };
  }

  if (action.type === SET_CAMPAIGNS) {
    return { ...state, campaigns: action.payload };
  }

  return state;
};

// actions creators
export const setCampaign = (campaign: Campaign) => ({
  payload: campaign,
  type: SET_CAMPAIGN,
});

export const setCampaigns = (campaigns: NeighborCampaigns) => ({
  payload: campaigns,
  type: SET_CAMPAIGNS,
});

// actions
export const getCampaign =
  (source: string, campaignParameter: string) => async (dispatch: any) => {
    const campaign = await api.get('neighbor_registration_campaign', {
      campaign: campaignParameter,
      source,
    });

    dispatch(setCampaign(campaign));
  };

export const getCampaigns =
  (authUser: any, locale: string) =>
  async (dispatch: Dispatch<PayloadAction<NeighborCampaigns>>) => {
    const campaigns = await api.get('neighbor_campaigns', {}, locale, authUser);

    if (campaigns) {
      dispatch(setCampaigns(campaigns));
    }
  };

export default campaignReducers;
