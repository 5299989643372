import apiClient, { API_TYPES } from '@services/hafh/api';
import { LANG_LOCALE, PayloadAction } from '@utils/types';
import { Action, Dispatch } from 'redux';

const api = apiClient(API_TYPES.API);

type Tag = { id: number; name: string };

type TagsState = {
  activeTagIds: number[];
  tags: Tag[];
};

const initialState = {
  activeTagIds: [],
  tags: [],
};

// action types
export const SET_TAGS = 'hafh/tag/SET_TAGS';

// reducers
const tag = (state: TagsState = initialState, action: PayloadAction<Tag[]>) => {
  if (action.type === SET_TAGS) {
    return {
      ...state,
      tags: action.payload,
    };
  }

  return state;
};

// actions
export const getTags =
  (locale: LANG_LOCALE) => async (dispatch: Dispatch<Action>) => {
    const res = await api.get('tags', {}, locale);

    if (res) {
      dispatch({
        payload: res,
        type: SET_TAGS,
      });
    }
  };

export default tag;
