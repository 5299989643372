import apiClient, { API_TYPES } from '@services/hafh/api';
import { Neighbor } from '@services/hafh/types/generated';
import { COUNTRY_IDS } from '@utils/constants';
import { InviteQuotas, LANG_LOCALE, PayloadAction } from '@utils/types';
import { Dispatch } from 'redux';

const api = apiClient(API_TYPES.API);

export const validateReferralCode = (
  code: string,
  locale: LANG_LOCALE,
  handleError = () => {}
) =>
  api.getV2(`neighbors/invitations/${code}/validate`, locale, {
    handleError,
  });

export const redeemReferralCode = (
  code: string,
  locale: LANG_LOCALE,
  authUser: any,
  handleError = () => {}
) =>
  api.post(
    `neighbors/invitations/${code}/redeem`,
    { code },
    locale,
    authUser,
    undefined,
    handleError
  );

// action types
export const UPDATE_INVITATION_QUOTAS = 'hafh/invite/UPDATE_INVITATION_QUOTAS';
const initialState = {};

// actions creators
export const updateInviteQuotas = (inviteQuotas: InviteQuotas) => ({
  payload: inviteQuotas,
  type: UPDATE_INVITATION_QUOTAS,
});

export const getInvitationQuotas =
  ({
    authUser,
    locale,
    neighbor,
  }: {
    authUser: any;
    locale: LANG_LOCALE;
    neighbor: Neighbor;
  }) =>
  async (dispatch: Dispatch<PayloadAction<InviteQuotas>>) => {
    if (neighbor.registration_country?.id === COUNTRY_IDS.japan) {
      const invite = await api.getV2('neighbors/invitation_quotas', locale, {
        authUser,
      });

      if (invite) {
        dispatch(updateInviteQuotas(invite));
      }
    }
  };

// reducers
const invite = (
  state = initialState,
  action: { payload: InviteQuotas; type: string }
) => {
  if (action.type === UPDATE_INVITATION_QUOTAS) {
    return action.payload;
  }

  return state;
};

export default invite;
