// action types
export const START = 'hafh/loader/START';

export const STOP = 'hafh/loader/STOP';

// reducers
const Loader = (
  state = false,
  action: { payload: boolean; type: typeof START | typeof STOP }
) => {
  if (action.type === START || action.type === STOP) {
    return action.payload;
  }

  return state;
};

// actions
export const startLoader = () => ({
  payload: true,
  type: START,
});

export const stopLoader = () => ({
  payload: false,
  type: STOP,
});

export default Loader;
