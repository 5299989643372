import { User } from '@firebase/auth-types';
import apiClient, { API_TYPES } from '@services/hafh/api';
import { Goal, LANG_LOCALE, PayloadAction } from '@utils/types';
import { Dispatch } from 'redux';

const api = apiClient(API_TYPES.API);

// @ts-ignore
const initialState: Goal = {};

export const UPDATE_GOALS = 'hafh/goals/UPDATE_GOALS';

const Goals = (
  state = initialState,
  action: { payload: Goal; type: typeof UPDATE_GOALS }
) => {
  if (action.type === UPDATE_GOALS) {
    return action.payload;
  }

  return state;
};

export const updateGoals = (goals: Goal) => ({
  payload: goals,
  type: UPDATE_GOALS,
});

export const getGoals =
  (authUser: User, locale: LANG_LOCALE, params = {}) =>
  async (dispatch: Dispatch<PayloadAction<Goal>>) => {
    const goals = await api.get('neighbors/goals', params, locale, authUser);

    if (goals) {
      dispatch(updateGoals(goals));

      return goals;
    }
  };

export const addGoals =
  (
    authUser: User,
    locale: LANG_LOCALE,
    guestNumber: number,
    nights: number,
    roomTypeId: number
  ) =>
  async (dispatch: Dispatch<PayloadAction<Goal>>) => {
    const goals = await api.post(
      'neighbors/goals',
      {
        guests: guestNumber,
        nights,
        property_room_type_id: roomTypeId,
      },
      locale,
      authUser
    );

    if (goals) {
      dispatch(updateGoals(goals));
    }
  };

export const editGoals =
  (
    authUser: User,
    locale: LANG_LOCALE,
    guestNumber: number,
    nights: number,
    roomTypeId: number
  ) =>
  async (dispatch: Dispatch<PayloadAction<Goal>>) => {
    const goals = await api.put(
      'neighbors/goals',
      {
        guests: guestNumber,
        nights,
        property_room_type_id: roomTypeId,
      },
      locale,
      authUser
    );

    if (goals) {
      dispatch(updateGoals(goals));
    }
  };

export const deleteGoals =
  (authUser: User, locale: LANG_LOCALE, roomTypeId: number) =>
  async (dispatch: Dispatch<PayloadAction<Goal>>) => {
    const goals = await api.delete(
      'neighbors/goals',
      {
        property_plan_id: roomTypeId,
      },
      locale,
      authUser
    );

    if (goals) {
      dispatch(updateGoals(goals));
    }
  };

export default Goals;
