/* eslint-disable */
import { PayloadAction } from '@utils/types';

// @ts-ignore
const initialState: boolean | null = null;

// action types
export const SET_EMAIL_VERIFIED = 'hafh/emailVerified/SET_EMAIL_VERIFIED';

// reducers
const emailVerified = (
  state = initialState,
  action: PayloadAction<boolean | null>
) => {
  switch (action.type) {
    case SET_EMAIL_VERIFIED:
      return action.payload;
    default:
      return state;
  }
};

// actions
export const setEmailVerified = (verified = initialState) => ({
  type: SET_EMAIL_VERIFIED,
  payload: verified,
});

export default emailVerified;
