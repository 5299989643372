// action types
export const START = 'hafh/loaderLocation/START';

export const STOP = 'hafh/loaderLocation/STOP';

// reducers
const LoaderLocation = (
  state = true,
  action: { payload: boolean; type: typeof START | typeof STOP }
) => {
  if (action.type === START || action.type === STOP) {
    return action.payload;
  }

  return state;
};

// actions
export const startLoaderLocation = () => ({
  payload: true,
  type: START,
});

export const stopLoaderLocation = () => ({
  payload: false,
  type: STOP,
});

export default LoaderLocation;
