/* eslint-disable */
import apiClient, { API_TYPES } from '@services/hafh/api';

const api = apiClient(API_TYPES.API);

const initialState = {
  coinHistories: [],
};

// action types
export const SET_COIN_HISTORY = 'hafh/neighborCoinHistory/SET_COIN_HISTORY';

// reducers
const neighborCoinHistory = (state = initialState, action) => {
  switch (action.type) {
    case SET_COIN_HISTORY:
      return {
        coinHistories: action.payload,
      };
    default:
      return state;
  }
};

// actions
export const getCoinHistories = (authUser, locale) => async (dispatch) => {
  const res = await api.get('neighbor_coin_histories', {}, locale, authUser);

  if (res) {
    dispatch({
      type: SET_COIN_HISTORY,
      payload: res,
    });
  }
};

export default neighborCoinHistory;
