import { REFERRAL_CODE_LOCALSTORAGE_KEY } from '@utils/constants';
import utils, { isEmpty, pickBy } from '@utils/utils';

const analytics = {
  campaignApplied() {
    return (
      this.isAmbassadorCampaign() ||
      this.isReferralCampaign() ||
      this.isCampaign('tabippo', 'coinup_20190724') ||
      this.isCampaign('freelance-jp', 'coinup_20190724') ||
      this.isCampaign('mail', 'ambassador_202007') ||
      this.isCampaign('partner', '2020special011') ||
      this.isCampaign('globalnomad', '2020special012') ||
      this.isCampaign('crew', '2020special013') ||
      this.isCampaign('techbiz', '2020special014') ||
      this.isCampaign('carstay', '2020special015') ||
      this.isCampaign('fb', 'adlp') ||
      this.isCampaign('google', 'brandadlp') ||
      this.isCampaign('google', 'bigadlp') ||
      this.isCampaign('google', 'adlprm') ||
      this.isCampaign('yahoo', 'brandadlp') ||
      this.isCampaign('yahoo', 'bigadlp') ||
      this.isCampaign('miles', '2021special12')
    );
  },

  isAmbassadorCampaign: () => {
    const data = localStorage.getItem('hafh_analytics');

    if (data === null || data === '') {
      return null;
    }
    const params = JSON.parse(data);

    return (
      !isEmpty(params) &&
      params.utm_source === 'ambassador' &&
      [
        '2020special001',
        '2020special002',
        '2020special003',
        '2020special004',
        '2020special005',
        '2020special006',
        '2020special007',
        '2020special008',
        '2020special009',
        '2020special010',
        '2020special016',
        '2020special017',
        '2020special018',
        '2020special019',
        '2020special020',
        '2020special021',
        '2020special022',
        '2020special023',
      ].includes(params.utm_campaign)
    );
  },

  isCampaign: (source: string, campaign: string) => {
    const data = localStorage.getItem('hafh_analytics');

    if (data === null || data === '') {
      return null;
    }
    const params = JSON.parse(data);

    return (
      !isEmpty(params) &&
      params.utm_source === source &&
      params.utm_campaign === campaign
    );
  },

  isReferralCampaign: () =>
    !!localStorage.getItem(REFERRAL_CODE_LOCALSTORAGE_KEY),

  persistParams: () => {
    const params = utils.getParams(window.location.search);
    // TODO: modify pickBy in utils.ts
    const utmParams = pickBy(params, (_: any, k: any) => k.startsWith('utm_'));

    if (!isEmpty(utmParams)) {
      localStorage.setItem('hafh_analytics', JSON.stringify(utmParams));
    }
  },

  removeParams: () => {
    localStorage.removeItem('hafh_analytics');
  },

  restoreParams: () => {
    const data = localStorage.getItem('hafh_analytics');

    if (data === null || data === '') {
      return null;
    }

    return JSON.parse(data);
  },
};

export default analytics;
