/* eslint-disable */
import { User } from '@firebase/auth-types';
import axios from 'axios';

import handleError from '@utils/error-handler';
import ErrorReporting from '@utils/error-reporting';

import { LANG_LOCALE } from '@utils/types';
import { isEmpty } from '@utils/utils';

export const API_TYPES = {
  WP: 'WP',
  API: 'API',
};

const API_URLS: any = {
  WP: process.env.NEXT_PUBLIC_WP_API_URL,
  API: process.browser
    ? process.env.NEXT_PUBLIC_API_LOCALHOST
    : process.env.NEXT_PUBLIC_API_URL,
};

const ApiClient = (type: string = API_TYPES.API, adapter?: any) => ({
  async request(
    path: string,
    params = {},
    locale: string,
    authUser: any,
    forceRefreshToken = false,
    headers = {},
    customHandleError?: any
  ) {
    const url = `${API_URLS[type]}/${path}`;

    headers = await addAuthorizationHeader(
      headers,
      authUser,
      forceRefreshToken
    );
    headers = addLocaleHeader(headers, locale);

    const options = {
      url,
      ...params,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      adapter,
    };

    try {
      const { data, headers } = await axios(options);
      let res;

      if (data.status === 401) {
        // TODO: redirect to login
      }

      if (headers['x-wp-total'] !== undefined) {
        res = {
          total: headers['x-wp-total'],
          data,
        };
      } else {
        res = data;
      }

      return res;
    } catch (error) {
      // re-try once
      if (
        !forceRefreshToken &&
        /Please sign in again/.test(error?.response?.data?.error)
      ) {
        await this.request(
          path,
          params,
          locale,
          authUser,
          true, // force refresh id token
          headers,
          customHandleError
        );
      } else {
        if (type !== API_TYPES.WP) {
          if (customHandleError) {
            customHandleError(error);
          } else {
            handleError(error);
          }
        }
      }

      return false;
    }
  },

  get(
    path: string,
    params = {},
    locale: string,
    authUser?: any,
    headers?: any,
    handleError: any = null
  ) {
    return this.request(
      path,
      {
        params,
        method: 'GET',
      },
      locale,
      authUser,
      false,
      headers,
      handleError
    );
  },

  // TODO: replace all `get` with `getV2` and rename getV2 to get
  getV2(
    path: string,
    locale: LANG_LOCALE,
    {
      params,
      authUser,
      headers,
      handleError,
    }: {
      params?: any;
      authUser?: User;
      headers?: any;
      handleError?: (e: any) => void;
    }
  ) {
    return this.request(
      path,
      {
        params,
        method: 'GET',
      },
      locale,
      authUser,
      false,
      headers,
      handleError
    );
  },

  post(
    path: string,
    data = {},
    locale: string,
    authUser?: any,
    headers?: any,
    handleError?: (e: any) => void
  ) {
    return this.request(
      path,
      {
        data,
        method: 'POST',
      },
      locale,
      authUser,
      false,
      headers,
      handleError
    );
  },

  put(
    path: string,
    data = {},
    locale: string,
    authUser?: any,
    headers?: any,
    handleError = null
  ) {
    return this.request(
      path,
      {
        data,
        method: 'PUT',
      },
      locale,
      authUser,
      false,
      headers,
      handleError
    );
  },

  delete(
    path: string,
    data = {},
    locale: string,
    authUser?: any,
    headers?: any
  ) {
    return this.request(
      path,
      {
        data,
        method: 'DELETE',
      },
      locale,
      authUser,
      false,
      headers
    );
  },
});

const addAuthorizationHeader = async (
  headers: any,
  authUser: any,
  forceRefreshToken: boolean
) => {
  if (isEmpty(authUser)) {
    return headers;
  }

  try {
    const authToken = await authUser.getIdToken(forceRefreshToken);

    headers = {
      Authorization: `Bearer ${authToken}`,
      ...headers,
    };

    return headers;
  } catch (error) {
    handleError({
      response: { data: { error: 'Session expired, please refresh.' } },
    });
    ErrorReporting.captureException(error);
    throw error;
  }
};

const addLocaleHeader = (headers: any, locale: string) => {
  if (!locale) {
    return headers;
  }

  return {
    'Accept-Language': locale,
    ...headers,
  };
};

export default ApiClient;
