/* eslint-disable */
import apiClient, { API_TYPES } from '@services/hafh/api';

const api = apiClient(API_TYPES.API);

const initialState = [];

export const AVAILABLE_FLIGHTS = 'hafh/availableFlights/AVAILABLE_FLIGHTS';

const AvailableFlights = (state = initialState, action) => {
  switch (action.type) {
    case AVAILABLE_FLIGHTS:
      return [...action.payload];
    default:
      return state;
  }
};

export const updateAvailableFlights = (availableFlights = []) => ({
  type: AVAILABLE_FLIGHTS,
  payload: availableFlights,
});

export const getAvailableFlights =
  (locale: string, authUser = null) =>
  async (dispatch) => {
    const availableFlights = await api.get(
      'available_flights',
      {},
      locale,
      authUser
    );
    if (availableFlights) {
      dispatch(updateAvailableFlights(availableFlights));
      return availableFlights;
    }
  };

export default AvailableFlights;
