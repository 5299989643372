import reducer from '@redux/modules/reducer';
import { createWrapper } from 'next-redux-wrapper';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import thunk from 'redux-thunk';

interface ExtendedWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}

declare const window: ExtendedWindow;

const composeReduxDevelopmentToolsEnhancers =
  (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const makeStore = () =>
  createStore(
    reducer,
    composeReduxDevelopmentToolsEnhancers(applyMiddleware(thunk))
  );

export type AppStore = ReturnType<typeof makeStore>;

export type RootState = ReturnType<AppStore['getState']>;

export default createWrapper<Store<RootState>>(makeStore);
