/* eslint-disable */
import apiClient, { API_TYPES } from '@services/hafh/api';

const api = apiClient(API_TYPES.WP);

const initialState = [];

// action types
export const UPDATE_CATEGORIES = 'hafh/topicCategories/UPDATE_CATEGORIES';

// reducers
const TopicCategories = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CATEGORIES:
      return action.payload;
    default:
      return state;
  }
};

// actions creators
export const updateCategories = (categories = []) => ({
  type: UPDATE_CATEGORIES,
  payload: categories,
});

// actions
export const getTopicCategories = (slug) => async (dispatch) => {
  const categories = await api.get('categories', { slug });

  if (categories) {
    dispatch(updateCategories(categories));
  }
};

export default TopicCategories;
