import firebase from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';
import 'firebase/auth';

class Firebase {
  constructor(locale) {
    if (firebase.apps.length === 0) {
      const firebaseConfig = JSON.parse(
        process.env.NEXT_PUBLIC_FIREBASE_CONFIG
      );
      firebase.initializeApp(firebaseConfig);
    }
    firebase.auth().languageCode = locale;
    this.auth = firebase.auth();
    this.doSignOut = this.doSignOut.bind(this);
    this.signinWithSns = this.signinWithSns.bind(this);
  }

  static linkAccounts(user) {
    const pendingCredential = sessionStorage.getItem('pendingCredential');

    if (pendingCredential) {
      const credential = JSON.parse(pendingCredential);
      let authCredential;

      if (credential.providerId === 'twitter.com') {
        authCredential = new firebase.auth.OAuthProvider(
          credential.providerId
        ).credential(credential.oauthAccessToken);
      } else {
        authCredential = new firebase.auth.OAuthProvider(
          credential.providerId
        ).credential(credential.oauthAccessToken, credential.oauthTokenSecret);
      }
      authCredential.accessToken = credential.oauthAccessToken;
      authCredential.secret = credential.oauthTokenSecret;
      user.linkWithCredential(authCredential);

      sessionStorage.removeItem('pendingCredential');
    }
  }

  async doSignOut() {
    try {
      await this.auth.signOut();

      return true;
    } catch {
      return false;
    }
  }

  // https://developers.line.biz/en/docs/line-login/integrate-line-login/#making-an-authorization-request
  static buildLineUrl() {
    const redirectUri = process.env.NEXT_PUBLIC_LINE_REDIRECT_URI;
    const clientId = process.env.NEXT_PUBLIC_LINE_CLIENT_ID;
    const state = uuidv4();
    const nonce = '09876xyz';
    const scope = 'profile%20openid%20email';
    const responseType = 'code';

    return `https://access.line.me/oauth2/v2.1/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}&nonce=${nonce}&bot_prompt=aggressive`;
  }

  async signInWithCustomToken(token) {
    await this.auth.signInWithCustomToken(token);
  }

  async signinWithSns(providerId) {
    const provider = new firebase.auth.OAuthProvider(providerId);
    await this.auth.signInWithRedirect(provider);
  }
}

export default Firebase;
