import css from 'styled-jsx/css';

const styles = css`
  .toast-close {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

export default styles;
