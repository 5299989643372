/* eslint-disable */
import classNames from 'classnames';
import React from 'react';

import COLORS from '@utils/colors';

import styles from './css';

type ColorProp = keyof typeof COLORS | '';
type Props = {
  text?: string | number;
  color?: ColorProp;
  lineThrough?: boolean;
  italic?: boolean;
  underline?: boolean;
  children?: React.ReactNode;
  tag?: keyof JSX.IntrinsicElements;
};

const defaultProps = {
  text: null,
  color: '',
  lineThrough: false,
  italic: false,
  underline: false,
  children: null,
};

const getColorStyle = (colorKey?: ColorProp) =>
  colorKey && colorKey.length > 0 ? { color: COLORS[colorKey] } : undefined;
const renderNumberZero = (text?: string | number) => (text === 0 ? '0' : text);

export const TypoXXXS = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames('typo', 'typo-xxxs', {
      'line-through': lineThrough,
      underline,
      italic,
    })}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoXXXS.defaultProps = defaultProps;

export const TypoXXS = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames('typo', 'typo-xxs', {
      'line-through': lineThrough,
      underline,
      italic,
    })}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoXXS.defaultProps = defaultProps;

export const TypoXS = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames('typo', 'typo-xs', {
      'line-through': lineThrough,
      underline,
      italic,
    })}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoXS.defaultProps = defaultProps;

export const TypoS = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames('typo', 'typo-s', {
      'line-through': lineThrough,
      underline,
      italic,
    })}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoS.defaultProps = defaultProps;

export const TypoM = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames('typo', 'typo-m', {
      'line-through': lineThrough,
      underline,
      italic,
    })}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoM.defaultProps = defaultProps;

export const TypoL = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames('typo', 'typo-l', {
      'line-through': lineThrough,
      underline,
      italic,
    })}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoL.defaultProps = defaultProps;

export const TypoXL = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames('typo', 'typo-xl', {
      'line-through': lineThrough,
      underline,
      italic,
    })}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoXL.defaultProps = defaultProps;

export const TypoXXL = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames('typo', 'typo-xxl', {
      'line-through': lineThrough,
      underline,
      italic,
    })}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoXXL.defaultProps = defaultProps;

export const TypoXXXSBold = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames(
      'typo',
      'typo-xxxs',
      'typo-bold',

      {
        'line-through': lineThrough,
        underline,
        italic,
      }
    )}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoXXXSBold.defaultProps = defaultProps;

export const TypoXXSBold = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames(
      'typo',
      'typo-xxs',
      'typo-bold',

      {
        'line-through': lineThrough,
        underline,
        italic,
      }
    )}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoXXSBold.defaultProps = defaultProps;

export const TypoXSBold = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames(
      'typo',
      'typo-xs',
      'typo-bold',

      {
        'line-through': lineThrough,
        underline,
        italic,
      }
    )}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoXSBold.defaultProps = defaultProps;

export const TypoSBold = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames('typo', 'typo-s', 'typo-bold', {
      'line-through': lineThrough,
      underline,
      italic,
    })}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoSBold.defaultProps = defaultProps;

export const TypoMBold = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames('typo', 'typo-m', 'typo-bold', {
      'line-through': lineThrough,
      underline,
      italic,
    })}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoMBold.defaultProps = defaultProps;

export const TypoLBold = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames('typo', 'typo-l', 'typo-bold', {
      'line-through': lineThrough,
      underline,
      italic,
    })}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}
    <style jsx>{styles}</style>
  </Wrapper>
);

TypoLBold.defaultProps = defaultProps;

export const TypoXLBold = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames(
      'typo',
      'typo-xl',
      'typo-bold',

      {
        'line-through': lineThrough,
        underline,
        italic,
      }
    )}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoXLBold.defaultProps = defaultProps;

export const TypoXXLBold = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames(
      'typo',
      'typo-xxl',
      'typo-bold',

      {
        'line-through': lineThrough,
        underline,
        italic,
      }
    )}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoXXLBold.defaultProps = defaultProps;

export const TypoXXXLBold = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames(
      'typo',
      'typo-xxl',
      'typo-bold',

      {
        'line-through': lineThrough,
        underline,
        italic,
      }
    )}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoXXXLBold.defaultProps = defaultProps;

export const TypoXXXXLBold = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames(
      'typo',
      'typo-xxxxl',
      'typo-bold',

      {
        'line-through': lineThrough,
        underline,
        italic,
      }
    )}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoXXLBold.defaultProps = defaultProps;

export const TypoXXXXXXLBold = ({
  text,
  color,
  lineThrough,
  italic,
  underline,
  children,
  tag: Wrapper = 'span',
}: Props): JSX.Element => (
  <Wrapper
    className={classNames(
      'typo',
      'typo-xxxxxxl',
      'typo-bold',

      {
        'line-through': lineThrough,
        underline,
        italic,
      }
    )}
    style={{ ...getColorStyle(color) }}
  >
    {renderNumberZero(text) || children}

    <style jsx>{styles}</style>
  </Wrapper>
);

TypoXXLBold.defaultProps = defaultProps;
