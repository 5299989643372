const routes = {
  about: '/about',
  aboutFlight: '/about-flight',
  areaLandingPage: '/areas/[area_id]/properties',
  browse: '/browse',
  changePlan: '/change-plan',
  coin: '/coin',
  coinHistory: '/coin-history',
  commercialTransactions: '/commercial-transactions',
  companyProfile: '/company-profile',
  concierge: '/inbox/cs?tab=messages',
  contact: '/contact',
  creditCardEdit: '/credit-card/edit',
  driverLicense: '/identifications/driver-license',
  faq: 'https://hafh.zendesk.com/hc',
  faqCancelEn:
    'https://hafh.zendesk.com/hc/en-001/articles/4411282066457-How-can-I-cancel-my-membership-',
  faqCancelJa:
    'https://hafh.zendesk.com/hc/ja/articles/4411282066457-%E9%80%80%E4%BC%9A%E3%81%97%E3%81%9F%E3%81%84%E3%81%A7%E3%81%99',
  favoriteProperties: '/favorite-properties',
  flightReservations: '/flight-reservations',
  flightReservationsNew: '/flight-reservations/new',
  forgetPassword: '/reset-password',
  glbInviteDetail: '/invite/glb/[referralCode]',
  goals: '/goals',
  goalsEdit: '/goals/edit',
  goalsNew: '/goals/new',
  guideOfTravelStories: '/guide-of-travel-stories',
  hafhPlan: '/plan',
  identifications: '/identifications',
  inbox: '/inbox',
  inboxDetail: '/inbox/[id]',
  invite: '/invite',
  inviteDetail: '/invite/[referralCode]',
  invoice: '/invoice',
  invoiceCreate: '/invoice/create',
  japanLP: '/lp/japan?type=japan',
  koreaOptionalCollectingPersonalData:
    'https://kabukstyle.notion.site/7a98f24b99e44fd88e059b709f0e1c0e',
  koreaRequiredCollectingPersonalData:
    'https://kabukstyle.notion.site/1eae01e193484545a5ddf344181f973f',
  koreaRequiredCollectingUniqueIdentifyingData:
    'https://kabukstyle.notion.site/054367d7612e479088331bddbcc03eeb',
  koreaRequiredProvidingPersonalData:
    'https://kabukstyle.notion.site/3-1b6b1c5a81e14f1da9914cd55a3794f8',
  koreaRequiredProvidingUniqueIdentifyingData:
    'https://kabukstyle.notion.site/c0c3fe167d584c529c78c0d800174fb9',
  lineCallback: '/users/line/callback',
  newsItem: '/inbox/[id]?tab=news',
  newsItems: '/inbox?tab=news',
  notFound: '/404',
  notification: '/inbox/[id]?tab=notifications',
  notifications: '/inbox?tab=notifications',
  passport: '/identifications/passport',
  phoneNumberEdit: '/phone-number/edit',
  phoneNumberNew: '/phone-number/new',
  privacyPolicy: '/privacy-policy',
  profile: '/profile',
  profileCompleted: '/profile/create/complete',
  profileCreate: '/profile/create',
  profileDelete: '/profile/delete',
  profileDeleteComplete: '/profile/delete-complete',
  profileDeleteConfirm: '/profile/delete-confirm',
  profileEdit: '/profile/edit',
  profileFormForReservation: '/profile/form-for-reservation',
  profileRegistrationComplete: '/profile/registration-complete',
  profileSnooze: '/profile/snooze',
  profileVerifyEmail: '/profile/verify-email',
  properties: '/properties',
  property: '/properties/[id]',
  propertyMessage: '/inbox/[id]?tab=property',
  propertyMessages: '/inbox?tab=property',
  propertyPlanConfirm: '/properties/[id]/plans/[planId]/confirm',
  propertyPlanPaymentOptions: '/properties/[id]/plans/[planId]/payment-options',
  rank: '/rank',
  redeemReferralCode: '/redeem-referral-code',
  reservation: '/reservations/[confirmationNumber]',
  reservationVoucher: '/reservations/[confirmationNumber]/voucher',
  reservations: '/reservations',
  reservationsCreate: '/reservations/create',
  reservationsPurchaseCoin: '/reservations/purchase-coin',
  reservationsPurchaseCoinConfirm: '/reservations/purchase-coin/confirm',
  root: '/',
  signin: '/signin',
  signinConsent: '/signin-consent',
  signup: '/signup',
  subscribe: '/subscribe',
  support: '/inbox?tab=support',
  tags: '/tags',
  termsOfService: '/terms-of-service',
  termsOfTravelStories: '/terms-of-travel-stories',
  topic: '/topics/[id]',
  topics: '/topics',
  travelStories: '/travel-stories',
  travelStoryDetail: '/travel-stories/[travelStoryId]',
  travelStoryForm: '/travel-stories/new',
  travelStoryPreview: '/travel-stories/[travelStoryId]/preview',
  upgradePlan: '/upgrade-plan',
  v2FlightReservation: '/v2/flight-reservations/[id]',
  v2FlightReservationsNew: '/v2/flight-reservations/new',
  v2FlightReservationsNewConfirm: '/v2/flight-reservations/new/confirm',
  v2FlightReservationsNewFlights: '/v2/flight-reservations/new/flights',
  v2FlightReservationsNewPassengers: '/v2/flight-reservations/new/passengers',
  v2FlightReservationsTicket:
    '/v2/flight-reservations/[id]/ticket/[ticket-number]',
  verifyPhoneNumber: '/verify-phone-number',
  welcomeBack: '/welcome-back',
};

export const route = (r, data) => {
  let url = r;
  const params = url.match(/[^[\]]+(?=])/g);

  if (data && params && params.length > 0) {
    params.forEach((p) => {
      url = url.replace(`[${p}]`, data[p]);
    });
  }

  return url;
};

export const getCurrentUrl = (router) => {
  const { asPath, defaultLocale, locale } = router;

  return `${process.env.NEXT_PUBLIC_BASE_URL}${
    locale === defaultLocale ? '' : `/${locale}`
  }${asPath}`;
};

export const getUrlWithLocale = (router, locale, path) => {
  const { defaultLocale } = router;
  const localePathname =
    locale === defaultLocale || !locale ? '' : `/${locale}`;
  // Top page should be without Trailing Slash
  const pathname = path === routes.root ? '' : path || router.asPath;
  const origin = process.env.NEXT_PUBLIC_BASE_URL;

  return `${origin}${localePathname}${pathname}`;
};

export default routes;
