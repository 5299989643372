/* eslint-disable */
import apiClient, { API_TYPES } from '@services/hafh/api';
import { UnreadCount } from '@utils/types';

const api = apiClient(API_TYPES.API);

const initialState = {
  total: 0,
  message: 0,
  notification: 0,
  news_item: 0,
  support: 0,
};

// action types
export const SET_UNREAD_COUNT = 'hafh/unreadCount/SET_UNREAD_COUNT';

// reducers
const unreadCount = (
  state = initialState,
  action: { type: string; payload: UnreadCount }
) => {
  switch (action.type) {
    case SET_UNREAD_COUNT:
      return action.payload;
    default:
      return state;
  }
};

// actions creators
export const setUnreadCount = (unreadCount = {}) => ({
  type: SET_UNREAD_COUNT,
  payload: unreadCount,
});

// actions
export const getUnreadCount = (authUser, locale) => async (dispatch) => {
  const unreadCount = await api.get(
    'neighbors/unread_count',
    {},
    locale,
    authUser
  );

  dispatch(setUnreadCount(unreadCount));
};

export default unreadCount;
